import AxiosConfig from '@/api/config'

const baseURL = '/ropehours'

const getAllRopeHours = async () => {
    try {
        const { data } = await AxiosConfig.get('/ropehours/1')
        return data
    } catch (error) {
        console.error('Error in getAllRopeHours:', error)
        throw error
    }
}

const getRopeHoursByWorkSchedule = async (workScheduleID) => {
    try {
        const url = `/workschedule?work_schedule_id=${workScheduleID}`

        const { data } = await AxiosConfig.get(url)
        return data
    } catch (error) {
        console.error('Error in getRopeHoursByWorkSchedule:', error)
        throw error
    }
}

const updateRopeHours = async (ropeHourID, payload) => {
    try {
        const url = `${baseURL}/${ropeHourID}`

        const { data } = await AxiosConfig.patch(url, payload)
        return data
    } catch (error) {
        console.error('Error in updateRopeHours:', error)
        throw error
    }
}

const uploadSignature = async (ropeHourID, image) => {
    try {
        const url = `${baseURL}/${ropeHourID}/signature`

        const { data } = await AxiosConfig.post(url, { image })
        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in uploadSignature:', error)
        throw error
    }
}

const getRopeHoursSignature = async (ropeHourID) => {
    try {
        const url = `${baseURL}/${ropeHourID}/download-signature`

        const { data } = await AxiosConfig.get(url, {
            responseType: 'arraybuffer'
        })
        return data
    } catch (error) {
        console.error('Error in getRopeHoursSignature:', error)
        throw error
    }
}

export default {
    getAllRopeHours,
    getRopeHoursByWorkSchedule,
    updateRopeHours,
    uploadSignature,
    getRopeHoursSignature
}
