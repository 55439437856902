import AxiosConfig from '@/api/config'

const baseURL = '/forms'

const getAllForms = async (params) => {
    try {
        const { data } = await AxiosConfig.get(baseURL, { params })

        return data
    } catch (error) {
        console.error('Error in getAllForms:', error)
        throw error
    }
}

const getFormsTypes = async () => {
    try {
        const { data } = await AxiosConfig.get(`${baseURL}/types`)

        return data
    } catch (error) {
        console.error('Error in getFormsTypes:', error)
        throw error
    }
}

const getForm = async (id) => {
    try {
        const { data } = await AxiosConfig.get(`${baseURL}/${id}`)

        return data
    } catch (error) {
        console.error('Error in getForm:', error)
        throw error
    }
}

const createForm = async (payload) => {
    try {
        const { data } = await AxiosConfig.post(baseURL, payload)

        if (!data) return {}

        return data
    } catch (error) {
        console.error('Error in createForm:', error)
        throw error
    }
}

const updateForm = async (formID, payload) => {
    try {
        const url = `${baseURL}/${formID}`

        const { data } = await AxiosConfig.patch(url, payload)

        if (!data) return {}

        return data
    } catch (error) {
        console.error('Error in updateForm:', error)
        throw error
    }
}

const deleteForm = async (formID, payload) => {
    try {
        const url = `${baseURL}/${formID}`

        const { data } = await AxiosConfig.delete(url, payload)

        return data.data ? data.data : data
    } catch (error) {
        console.error('Error in deleteForm:', error)
        throw error
    }
}

const getFormSubmissionSignatures = async (id) => {
    try {
        const { data } = await AxiosConfig.get(
            `form-submissions/${id}/signatures`
        )

        return data
    } catch (error) {
        console.error('Error in getFormSubmissionSignatures:', error)
        throw error
    }
}

const uploadSignature = async (id, payload) => {
    try {
        const { data } = await AxiosConfig.post(
            `form-submissions/${id}/signature`,
            payload
        )

        return data
    } catch (error) {
        console.error('Error in uploadSignature:', error)
        throw error
    }
}

const createFormSubmission = async (payload) => {
    try {
        const url = 'form-submissions'

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return {}

        return data
    } catch (error) {
        console.error('Error in createFormSubmission:', error)
        throw error
    }
}

const updateFormSubmission = async (formID, payload) => {
    try {
        const url = `form-submissions/${formID}`

        const { data } = await AxiosConfig.patch(url, payload)

        if (!data) return {}

        return data
    } catch (error) {
        console.error('Error in updateFormSubmission:', error)
        throw error
    }
}

export default {
    getAllForms,
    createForm,
    updateForm,
    deleteForm,
    getForm,
    getFormsTypes,
    getFormSubmissionSignatures,
    uploadSignature,
    createFormSubmission,
    updateFormSubmission
}
