import AxiosConfig from '@/api/config'

const baseURL = '/tenants'

const getTenant = async (id) => {
    try {
        const { data } = await AxiosConfig.get(`${baseURL}/${id}`)

        if (!data) return []

        return data.data
    } catch (error) {
        console.error('Error in getTenant:', error)
        throw error
    }
}

const updateTenant = async (id, payload) => {
    try {
        const { data } = await AxiosConfig.put(`${baseURL}/${id}`, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in updateTenant:', error)
        throw error
    }
}

const downloadLogo = async (id) => {
    try {
        const url = `${baseURL}/${id}/download-logo`

        const { data } = await AxiosConfig.get(url)

        return data
    } catch (error) {
        console.error('Error in downloadLogo:', error)
        throw error
    }
}

const attachLogo = async (id, payload) => {
    try {
        const url = `${baseURL}/${id}/attach-logo`

        const { data } = await AxiosConfig.post(url, payload)

        return data
    } catch (error) {
        console.error('Error in attachLogo:', error)
        throw error
    }
}

const getTenantBilling = async (id, params) => {
    try {
        const url = `${baseURL}/${id}/invoices`

        const { data } = await AxiosConfig.get(url, { params })

        if (!data) return []

        return data.data
    } catch (error) {
        console.error('Error in getTenantBilling:', error)
        throw error
    }
}

const downloadInvoice = async (id, invoiceId) => {
    try {
        const url = `${baseURL}/${id}/invoices/${invoiceId}/download`

        const { data } = await AxiosConfig.get(url, {
            responseType: 'blob'
        })

        const fileURL = window.URL.createObjectURL(new Blob([data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', `${invoiceId}.pdf`)
        document.body.appendChild(fileLink)

        fileLink.click()

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in downloadInvoice:', error)
        throw error
    }
}

export default {
    getTenant,
    downloadLogo,
    updateTenant,
    attachLogo,
    getTenantBilling,
    downloadInvoice
}
