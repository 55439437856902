import AxiosConfig from '@/api/config'

const getAllCategories = async (model) => {
    try {
        const url = `${model}/categories`

        const { data } = await AxiosConfig.get(url)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in getAllCategories:', error)
        throw error
    }
}

const getCategory = async (model, id) => {
    try {
        const url = `${model}/categories/${id}`

        const { data } = await AxiosConfig.get(url)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in getCategory:', error)
        throw error
    }
}

const createCategory = async (model, payload) => {
    try {
        const url = `${model}/categories/`

        const { data } = await AxiosConfig.post(url, payload)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in createCategory:', error)
        throw error
    }
}

const deleteCategory = async (model, id) => {
    try {
        const url = `${model}/categories/${id}`

        const { data } = await AxiosConfig.delete(url)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in deleteCategory:', error)
        throw error
    }
}

export default {
    getAllCategories,
    deleteCategory,
    getCategory,
    createCategory
}
