import db from '@/offline/indexedDBHelper'
import { fetchAndStoreData } from '.'

const baseURL = '/inspections'

const getResultTypes = async () => {
    try {
        const url = `${baseURL}/result-types`
        return await fetchAndStoreData(url, db.inspections_result_types)
    } catch (error) {
        console.error('Error in getResultTypes:', error)
        throw error
    }
}

export default {
    getResultTypes
}
