import AxiosConfig from '@/api/config'

const baseURL = '/instructions'

const getAllInstructions = async (workScheduleID) => {
    try {
        const url = `${baseURL}?work_schedule_id=${workScheduleID}`

        const { data } = await AxiosConfig.get(url)

        return data || []
    } catch (error) {
        console.error('Error in getAllInstructions:', error)
        throw error
    }
}

const getAllInstructionsFilter = async (filters) => {
    try {
        const url = `${baseURL}`

        const { data } = await AxiosConfig.get(url, { params: filters })

        return data || []
    } catch (error) {
        console.error('Error in getAllInstructionsFilter:', error)
        throw error
    }
}

const createInstruction = async (workScheduleID, createInstructionsPayload) => {
    try {
        const { title, body, recipients } = createInstructionsPayload

        const payload = {
            work_schedule_id: workScheduleID,
            title,
            body,
            recipients
        }

        const { data } = await AxiosConfig.post(baseURL, payload)

        return data || []
    } catch (error) {
        console.error('Error in createInstruction:', error)
        throw error
    }
}

const updateInstructionStatus = async (instructionID, status) => {
    try {
        const url = `${baseURL}/${instructionID}/change-status?status=${status}`

        const { data } = await AxiosConfig.post(url)

        return data || []
    } catch (error) {
        console.error('Error in updateInstructionStatus:', error)
        throw error
    }
}

export default {
    getAllInstructions,
    getAllInstructionsFilter,
    createInstruction,
    updateInstructionStatus
}
