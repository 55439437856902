import AuthServices from '@/api/AuthServices'
import DashboardServices from '@/api/DashboardServices'
import InspectionsServices from '@/api/InspectionsServices'
import InventoryServices from '@/api/InventoryServices'
import JobsServices from '@/api/JobsServices'
import InvoicesServices from '@/api/InvoicesServices'
import ModelsServices from '@/api/ModelsServices'
import NavigationServices from '@/api/NavigationServices'
import OperativesServices from '@/api/OperativesServices'
import WorkSchedulesServices from '@/api/WorkSchedulesServices'
import ReportsServices from '@/api/ReportsServices'
import RopeHoursServices from '@/api/RopeHoursServices'
import InstructionsServices from '@/api/InstructionsServices'
import ExpensesServices from '@/api/ExpensesServices'
import UserServices from '@/api/UserServices'
import TenantServices from '@/api/TenantServices'
import FormsServices from '@/api/FormsServices'
import FormQuestionsServices from '@/api/FormQuestionsServices'
import FormAnswersServices from '@/api/FormAnswersServices'
import CategoriesServices from '@/api/CategoriesServices'
import ClientSignaturesServices from '@/api/ClientSignaturesServices'
import TagsServices from './TagsServices'
import AxiosConfig from '@/api/config'
import db from '@/offline/indexedDBHelper'
import store from '@/store'

export default {
    Auth: AuthServices,
    Tenant: TenantServices,
    Dashboard: DashboardServices,
    Inspections: InspectionsServices,
    Inventory: InventoryServices,
    Jobs: JobsServices,
    Invoices: InvoicesServices,
    Models: ModelsServices,
    Navigation: NavigationServices,
    Operatives: OperativesServices,
    WorkSchedules: WorkSchedulesServices,
    Reports: ReportsServices,
    RopeHours: RopeHoursServices,
    Instructions: InstructionsServices,
    Expenses: ExpensesServices,
    User: UserServices,
    FormsServices,
    Questions: FormQuestionsServices,
    Answers: FormAnswersServices,
    Categories: CategoriesServices,
    ClientSignatures: ClientSignaturesServices,
    Tags: TagsServices
}

export async function fetchAndStoreData (apiUrl, dbTable) {
    // Get user ID
    const userId = store.getters['AuthModule/getUserId']

    try {
        if (!window.indexedDB) {
            console.log(
                'Your browser does not support a stable version of IndexedDB. Such and such feature will not be available.'
            )
            const { data } = await AxiosConfig.get(apiUrl)
            const fetchedData = data.data ? data.data : data
            return fetchedData
        }

        const offline = userId
            ? await dbTable.where({ user_id: userId }).toArray()
            : []
        // console.log({ [dbTable.name]: offline })

        if (offline && offline.length > 0) return offline

        const { data } = await AxiosConfig.get(apiUrl)
        const fetchedData = data.data ? data.data : data

        if (!userId) return fetchedData

        // Save to IndexedDB
        const offlineData = fetchedData.map((item) => {
            // Check if item is a string
            if (typeof item === 'string') {
                // Handle string item case
                return { value: item, user_id: userId }
            } else {
                // Handle object item case
                return {
                    id: item.id,
                    user_id: userId,
                    ...item
                }
            }
        })

        await dbTable.bulkPut(offlineData)

        return fetchedData
    } catch (error) {
        // If offline, fetch from IndexedDB
        if (window.indexedDB && userId) {
            return await dbTable.where({ user_id: userId }).toArray()
        }
        return []
    }
}

export async function getAppVersion () {
    try {
        const { data: { version = '' } = {} } = await AxiosConfig.get(
            '/app-version'
        )

        if (!window.indexedDB) {
            return version
        }

        const offline = await db.versions.toArray()

        // If version is the same, return
        if (offline && offline.length) {
            if (offline[0].version === version) return version

            await db.delete()
            await db.open()
                .then(() => window.location.reload())
                .catch((err) => console.error('Could not open database', err))
        }
        // Save to IndexedDB
        await db.versions.add({
            version
        })

        return version
    } catch (error) {
        return ''
    }
}
