import AxiosConfig from '@/api/config'

const loginUser = async (loginPayload) => {
    try {
        const url = '/login'

        const { data } = await AxiosConfig.post(url, loginPayload)
        if (!data) return {}
        return data.data
    } catch (error) {
        console.error('Error in loginUser:', error)
        throw error
    }
}

const fetchUser = async (id) => {
    try {
        const url = `/users/${id}`

        const { data } = await AxiosConfig.get(url)
        if (!data) return {}
        return data
    } catch (error) {
        console.error('Error in fetchUser:', error)
        throw error
    }
}

export default {
    fetchUser,
    loginUser
}
