import AxiosConfig from '@/api/config'

const getMenuItems = async () => {
    try {
        const url = '/menuitems'

        const { data } = await AxiosConfig.get(url)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in getMenuItems:', error)
        throw error
    }
}

export default {
    getMenuItems
}
