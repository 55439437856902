import AxiosConfig from '@/api/config'

const baseURL = '/users'

const getTimeZones = async () => {
    try {
        const url = `${baseURL}/available-timezones`

        const { data } = await AxiosConfig.get(url)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in getTimeZones:', error)
        throw error
    }
}

const onTrial = async () => {
    try {
        const url = `${baseURL}/on-trial`

        const { data } = await AxiosConfig.get(url)

        if (!data) return []

        return data
    } catch (error) {
        console.error('Error in onTrial:', error)
        throw error
    }
}

export default {
    getTimeZones,
    onTrial
}
