import { fetchAndStoreData } from '.'
import db from '@/offline/indexedDBHelper'

const baseURL = '/inventoryitems'

const getMainTypes = async () => {
    try {
        const url = `${baseURL}/types`

        const data = await fetchAndStoreData(url, db.inventory_types)

        return data.map((t) => {
            return {
                id: t.id,
                value: t.name,
                name: t.name
            }
        })
    } catch (error) {
        console.error('Error in getMainTypes:', error)
        throw error
    }
}

const getStatusTypes = async () => {
    try {
        const url = `${baseURL}/status-types`

        return await fetchAndStoreData(url, db.inventory_statuses)
    } catch (error) {
        console.error('Error in getStatusTypes:', error)
        throw error
    }
}

const getTags = async () => {
    try {
        const url = `${baseURL}/tags`

        return await fetchAndStoreData(url, db.inventory_categories)
    } catch (error) {
        console.error('Error in getTags:', error)
        throw error
    }
}

export default {
    getMainTypes,
    getStatusTypes,
    getTags
}
